<template>
	<div id="app">
		<!--    <nav>-->
		<!--      <router-link to="/">Home</router-link> |-->
		<!--      <router-link to="/about">About</router-link>-->
		<!--    </nav>-->

		<router-view v-if="isRouteActive"></router-view>
	</div>
</template>

<style>
#app {
	font-family: 'Helvetica Neue', 'Hiragino Sans GB', 'WenQuanYi Micro Hei',
		'Microsoft Yahei', sans-serif;
	height: 100vh;
	padding: 0;
	margin: 0;
	font-size: 15px;
}
</style>
<script setup lang="ts">
import { provide, ref, nextTick } from 'vue'

const isRouteActive = ref(true)
provide('reload', () => {
	isRouteActive.value = false
	nextTick(() => {
		localStorage.setItem('selectTab', '告警信息')
		isRouteActive.value = true
		this.selectTab = localStorage.getItem('selectTab')
	})
})
</script>
