const salutationList= [
    { name: '尊重所有声音但只成为自己' },
    { name: '理想生活需要一点热爱和心动' },
    { name: '别听世俗的耳语，去看自己喜欢的风景' },
    { name: '即使单枪匹马也要勇敢无畏' },
    { name: '阅己，悦己，越己' },
    { name: '阅书，越山，悦己' },
    { name: '手持烟火以谋生，心怀诗意以谋爱' },
    { name: '至此鲜花赠自己，纵马踏花向自由' },
    { name: '要银河不要迎合' },
    { name: '每一天都是人生限定' },
    { name: '慢慢理解自己，慢慢更新自己' },
    { name: '路过山水万程，祝自己与温柔重逢' },
    { name: '不说永远，在每个瞬间' },
    { name: '且停且忘且随风 且行且看且从容' },
    { name: '像生命所有转折 要你认可' },
    { name: '落日沉迷于橘色的海，晚风沦陷于赤诚的爱' },
    { name: '你经过我身旁，像鹿穿过花岗' },
    { name: '在谷底也要开花，在海底也要望月' },
    { name: '无需比较，我独我' },
    { name: '满船的烟波，眼底的星河' },
    { name: '当我在追光，我与光同航' },
    { name: '海风吹不散玫瑰，月色难逃皎皎' },
    { name: '心有山海，静而不争' },
    { name: '真诚是人生旅中永不作废的车票' },
    { name: '看世界听自己' },
    { name: '在我喜欢的状态里生活' },
    { name: '恰到好处描述今天' },
    { name: '人生缓缓自有答案' },
    { name: '行己所爱，爱己所行' },
    { name: '答案在明天，每天的明天' },
    { name: '等时间嘉许，等春风得意' },
    { name: '所有的努力都在慢慢变得清晰' },
    { name: '愿你执着于理想,纯粹于当下' },
    { name: '对着黄昏许愿,玫瑰永不凋零' },
    { name: '热爱是不会过期的' },
    { name: '时间酿酒，余味成花' },
    { name: '心至苍穹处，目近星河远' },
    { name: '人间总有一两风，填我十万八千梦' },
    { name: '把向往的地方都变成走过的路' },
    { name: '生命只有去爱才有意义' },
    { name: '勇敢一点，你比想象的更好' },
    { name: '允许自己做自己，允许别人做别人' },
    { name: '比起过往，前方更值得期待' },
    { name: '人生缓缓，自有答案' },
    { name: '不需要理解，各有各的浪漫' },
    { name: '听一万种声音，但只成为自己' },
    { name: '心存希冀，沐光而行' },
    { name: '愿你成为自己的太阳，无需借谁的光' },
    { name: '做有趣的人，小才，微善，即可' },
    { name: '前途与玫瑰，来日与方长' },
    { name: '这一生，是场浪漫的康复' },
    { name: '生而自由，爱而无畏' },
]
const NationalSalutation = '九州同庆贺华诞，拳拳之心报祖国！'
const SpringFestivalSalutation = '春来万物生，节庆华夏丰，春节快乐！'
const MidAutumnFestivalSalutation = '但愿人长久，千里共婵娟！'
const DragonBoatFestival = '粽子飘香，龙舟竞渡，端午安康！'
const LabourDay = '致敬每一滴汗水，赞美每一份辛勤，劳动节快乐！'
const WomenDay = '是家庭的守护者，也是社会的建设者，致敬巾帼力量！'
const holidaysSalutation = {
    '1': { // 1月
        '1': '春来万物生，节庆华夏丰，春节快乐！',
    },
    '3': {
        '8': '是家庭的守护者，也是社会的建设者，致敬巾帼力量！',
    },
    '5': {
        '1': '致敬每一滴汗水，赞美每一份辛勤，劳动节快乐！',
    },
    '10': {
        '1': '九州同庆贺华诞，拳拳之心报祖国！',
    },
};

// 中秋节
function isMidAutumnFestival() {
    const Lunar = require('chinese-lunar');
    const today = new Date();
    const lunarToday = Lunar.solarToLunar(today);
    // 端午节是农历五月初五
    return lunarToday.month === 7 && lunarToday.day === 15;
}

// 端午节
function isDragonBoatFestival() {
    const Lunar = require('chinese-lunar');
    const today = new Date();
    const lunarToday = Lunar.solarToLunar(today);
    // 端午节是农历五月初五
    return lunarToday.month === 4 && lunarToday.day === 5;
}

// 清明节
// function isQingmingFestival(fullYear) {
//     let date = new Date();
//     let currentMonth = date.getMonth() + 1;
//     let currentDay = date.getDate();
//     //清明节的日期是不固定的，规律是：闰年开始的前2年是4月4日，闰年开始的第3年和第4年是4月5日
//     if(isLeapYear(fullYear) || isLeapYear(fullYear -1)) {
//         return currentMonth === 4 && currentDay === 4;
//     } else {
//         return currentMonth === 4 && currentDay === 5;
//     }
// }
// // 判断是否是闰年
// function isLeapYear (Year) {
//     return ((Year % 4) === 0) && ((Year % 100) !== 0) || ((Year % 400) === 0);
// }

function getSalutation(){
    let date = new Date();
    let currentMonth = date.getMonth() + 1;
    let currentDay = date.getDate();
    let salutation;

    if (isMidAutumnFestival()) {
        salutation = MidAutumnFestivalSalutation
    } else if(isDragonBoatFestival()){
        salutation = DragonBoatFestival
    } else if (holidaysSalutation[currentMonth] && holidaysSalutation[currentMonth][currentDay]) {
        salutation = holidaysSalutation[currentMonth][currentDay];
    } else {
        salutation = getRandomSalutation();
    }
    return salutation;
}

function getRandomSalutation() {
    return salutationList[
        Math.round(Math.random() * (salutationList.length - 1))
        ].name;
}
export default {
    salutationList,
    holidaysSalutation,
    getSalutation,
    MidAutumnFestivalSalutation,
    DragonBoatFestival
}