import axios from 'axios'
import router from '@/router'
import Element from 'element-ui'

const axiosRequest = axios.create({
	//阿里云服务器
	// baseURL: 'http://192.168.31.107:8899',
	// baseURL: 'http://192.168.31.186:8081',
	// baseURL: 'http://39.97.164.42:8899',
	//正式服务器
	baseURL : "https://yunbaobei.yeetrc.com:8081",
	// timeout: 5000,
})

axiosRequest.interceptors.request.use((config) => {
	if (localStorage.getItem('satoken')) {
		config.headers.satoken = localStorage.getItem('satoken')
	}
	return config
})
axiosRequest.interceptors.response.use((res) => {
	//返回code后之后要做的操作例如：token失效后跳到登陆页面
	if (res.data.status === 11013) {
		let path = window.location.href.split('/')
		if (path[path.length - 1] !== 'login') {
			Element.Message.warning(res.data.message)
			router.push('/login')
		}
	}
	return res
})
export default axiosRequest
