<template>
	<div class="login_container">
		<div class="login_logo">
			<img
				src="../assets/taiji.jpg"
				style="display: inline-block; width: 300px"
			/>
		</div>
		<h3 class="fontText">云宝备——太极风控云备份数据管理平台</h3>
		<div class="login_box">
			<!--登录表单区域-->
			<el-tabs :stretch="true" v-model="activeName" @tab-click="handle">
				<!--账户密码登录-->
				<el-tab-pane label="用户名密码登录" name="first">
					<el-form :model="pwdLoginForm" :rules="pwdRules" ref="pwdLoginForm">
						<!--用户名-->
						<el-form-item prop="username">
							<el-input
								v-model="pwdLoginForm.username"
								placeholder="用户名 汉字/字母/数字/下划线"
								clearable
								prefix-icon="el-icon-user-solid"
							></el-input>
						</el-form-item>
						<!--密码-->
						<el-form-item prop="password">
							<el-input
								v-model="pwdLoginForm.password"
								placeholder="密码"
								type="password"
								show-password
								prefix-icon="el-icon-lock"
							></el-input>
						</el-form-item>
						<!--验证码-->
						<div class="code">
							<el-row :gutter="15">
								<el-col :span="8" style="width: 250px">
									<el-form-item
										label="图形验证码"
										label-width="122px"
										prop="checkCode"
									>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-input
										placeholder="请输入验证码"
										v-model="pwdLoginForm.checkCode"
										class="input"
									></el-input
								></el-col>
								<el-col :span="8" style="margin-left: 80px; margin-top: -42px">
									<VerificationCode
										ref="VerificationCode"
										:changeCode.sync="identifyCode"
										class="checkCode"
									></VerificationCode>
								</el-col>
							</el-row>
						</div>
						<!--登录-->
						<el-form-item>
							<el-button
								type="primary"
								@click="submitForm('pwdLoginForm')"
								class="submit-btn"
								@keyup.enter="submit"
								id="login"
								>登录</el-button
							>
						</el-form-item>
						<el-col :span="5">
							<el-button type="text" style="margin-top: -2px" @click="vxLogin">
								其他方式登录
							</el-button>
						</el-col>
						<el-col :span="2" style="margin-top: -4px">
							<el-button @click="vxLogin" type="text">
								<el-image
									:src="require(`../assets/wxLogo.png`)"
									style="width: 20px"
								></el-image>
							</el-button>
						</el-col>
						<!--忘记密码-->
						<div>
							<el-button type="text" @click="forget" class="forgetPw"
								>忘记密码</el-button
							>
						</div>
						<el-col :span="13" style="margin-top: -40px; margin-left: 20px">
							<el-button type="text" @click="confirmAdmin" class="confirmAdmin"
								>更换管理员</el-button
							>
						</el-col>
						<el-col :span="3" style="margin-top: -40px; margin-left: 260px">
							<el-button type="text" @click="register" class="register"
								>注册账户</el-button
							>
						</el-col>
					</el-form>
				</el-tab-pane>

				<!--手机或邮箱验证码登录-->
				<el-tab-pane label="验证码登录" name="second">
					<!--手机验证码-->
					<el-form
						:model="codeLoginForm"
						:rules="
							this.showCodeLogin === true ? codeRulesPhone : codeRulesEmail
						"
						ref="codeLoginForm"
					>
						<el-form-item prop="phone">
							<el-input
								v-model="codeLoginForm.phone"
								:placeholder="ptext"
								clearable
								prefix-icon="el-icon-user-solid"
							>
							</el-input>
						</el-form-item>
						<el-form-item prop="phoneCode">
							<el-input
								v-model="codeLoginForm.phoneCode"
								placeholder="验证码"
								clearable
								prefix-icon="el-icon-message"
								style="width: 310px"
							></el-input>
							<el-button
								@click="getLoginPhoneCode"
								style="margin-left: 25px"
								:disabled="!show"
								v-model="codeLoginForm.phoneGet"
							>
								<span v-show="show">获取验证码</span>
								<span v-show="!show" class="count">{{ count }}秒后获取</span>
							</el-button>
						</el-form-item>
						<!--忘记密码-->
						<!--登录-->
						<el-form-item>
							<el-button
								type="primary"
								@click="submitFormLogin('codeLoginForm')"
								class="submit-btn"
								@keyup.enter="submit1"
								id="login1"
								>登录</el-button
							>
						</el-form-item>
						<el-row>
							<el-col :span="5">
								<el-button type="text" @click="vxLogin" style="margin-top: 3px">
									<span>其他方式登录</span>
								</el-button>
							</el-col>
							<el-col :span="2">
								<el-button @click="vxLogin" type="text">
									<el-image
										:src="require(`../assets/wxLogo.png`)"
										style="width: 20px"
									></el-image>
								</el-button>
							</el-col>
							<div style="margin-top: 3px; margin-left: 215px">
								<el-button type="text" @click="forget">忘记密码</el-button>
							</div>
							<el-col :span="13" style="margin-top: -45px; margin-left: 295px">
								<el-button type="text" @click="confirmAdmin"
									>更换管理员</el-button
								>
							</el-col>
							<el-col :span="3" style="margin-top: -45px; margin-left: 390px">
								<el-button type="text" @click="register">注册账户</el-button>
							</el-col>
						</el-row>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		</div>
		<el-dialog
			title="微信扫码登录"
			:visible.sync="WXQRDialogVisible"
			width="30%"
			center
		>
			<div style="margin-left: 150px">
				<img :src="QRImgUrl" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
import VerificationCode from '@/components/VerificationCode'
// import axios from 'axios';
import axiosRequest from '@/axios'
import jsCookie from 'js-cookie'
import QRCode from 'qrcode'
export default {
	name: 'Login',
	components: { VerificationCode },
	data() {
		//用户名
		var checkUsername = (rules, value, callback) => {
			var reg = /^(?!_)[a-zA-Z0-9_\u4e00-\u9fa5]{1,16}$/
			if (!reg.test(this.pwdLoginForm.username)) {
				return callback(
					new Error(
						'用户名支持1-16位英文大小写、汉字、数字、下划线，下划线不能作为开头'
					)
				)
			} else {
				callback()
			}
		}
		//判断输入的验证码和图形验证码是否一致
		var checkCodes = (rules, value, callback) => {
			if (
				this.pwdLoginForm.checkCode.toLowerCase() ===
				this.identifyCode.toLowerCase()
			) {
				return callback()
			} else {
				callback(new Error('验证码不正确'))
			}
		}
		//验证手机号是否合法
		var checkMobile = (rules, value, callback) => {
			if (this.showCodeLogin === true) {
				const regMobile =
					/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
				if (regMobile.test(value) == true) {
					return callback()
				} else {
					callback(new Error('请输入合法的手机号'))
				}
			} else {
				const regEmail =
					/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
				if (regEmail.test(value) == true) {
					return callback()
				} else {
					callback(new Error('请输入合法的邮箱'))
					return
				}
			}
		}
		//验证输入的手机号验证码是否和存储的验证码相同
		var checkPhoneCode = (rules, value, callback) => {
			if (this.codeLoginForm.phone === '') {
				return callback('请先输入手机号')
			} else if (this.codeLoginForm.phoneCode === '1234') {
				return callback()
			} else {
				callback(new Error('验证码错误'))
				return
			}
		}

		//验证邮箱是否合法
		var checkEmail = (rules, value, callback) => {
			const regEmail =
				/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
			if (regEmail.test(value) == true) {
				return callback()
			} else {
				callback(new Error('请输入合法的邮箱'))
				return
			}
		}
		//验证输入的邮箱验证码是否和存储的验证码相同
		// var checkEmailCode=(rules,value,callback)=>{
		//   if(this.codeLoginForm2.emailCode==='1234') {
		//     return callback();
		//   }else{
		//     callback(new Error("验证码错误"));
		//     return
		//   }
		// };
		return {
			WXQRDialogVisible: false,
			activeName: 'first',
			ptext: '手机号',
			//用户名密码
			pwdLoginForm: {
				username: '',
				password: '',
			},
			showCodeLogin: true,
			checkCode: '',
			identifyCode: '', //当前生成的验证码
			//手机验证码
			show: true,
			timer: 0,
			count: 0,
			//规则
			pwdRules: {
				username: [
					{ required: true, message: '请输入用户名', trigger: 'blur' },
					{ validator: checkUsername, trigger: 'blur' },
				],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				checkCode: [
					{ required: true, message: '请输入验证码', trigger: 'blur' },
					{ validator: checkCodes, trigger: 'blur' },
				],
			},
			// codeRules:{
			//   phone:[
			//     {required: true, message: '请输入手机号', trigger: 'blur'},
			//     {validator: checkMobile,trigger: 'blur'},
			//   ],
			//   // phoneCode:[
			//   //   {required: true, message: '请输入验证码', trigger: 'blur'},
			//   //   {validator: checkPhoneCode,trigger: 'blur'},
			//   // ],
			//   email:[
			//     {required:true,message:'请输入邮箱', trigger:'blur'},
			//     {validator: checkEmail,trigger:'blur'}
			//   ],
			//   // emailCode: [
			//   //   {required: true, message: '请输入验证码', trigger: 'blur'},
			//   //   {validator: checkEmailCode,trigger: 'blur'},
			//   // ]
			// },
			codeRulesPhone: {
				phone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{ validator: checkMobile, trigger: 'blur' },
				],
			},
			codeRulesEmail: {
				phone: [
					{ required: true, message: '请输入邮箱', trigger: 'blur' },
					{ validator: checkEmail, trigger: 'blur' },
				],
			},
			//手机验证码
			codeLoginForm: {
				phone: '',
				phoneCode: '',
				phoneGet: '',
				count: '', //倒计时
			},
			// codeLoginForm2:{
			//   email:'',
			//   emailCode:'',
			//   emailGet:'',
			//   count:'', //倒计时
			// },
			QRImgUrl: '',
			uuid: '',
		}
	},
	computed: {},
	methods: {
		getLoginPhoneCode() {
			if (this.codeLoginForm.phone === '' && this.showCodeLogin === true) {
				this.$message.warning('请先输入手机号')
				return
			}

			if (
				!/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/.test(
					this.codeLoginForm.phone
				) &&
				this.showCodeLogin == true
			) {
				this.$message.warning('请输入合法的手机号')
				return
			}
			if (this.codeLoginForm.phone === '' && this.showCodeLogin === false) {
				this.$message.warning('请先输入邮箱')
				return
			}
			if (
				!/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
					this.codeLoginForm.phone
				) &&
				this.showCodeLogin === false
			) {
				this.$message.warning('请输入合法的邮箱')
				return
			}

			let url = ''
			if (this.showCodeLogin === true) {
				this.show = false
				url = '/user/phoneVerification'
				axiosRequest
					.post(url, {
						phone: this.codeLoginForm.phone,
						id: 1,
					})
					.then((res) => {
						if (res.data.status === 200) {
							//验证码倒计时
							if (!this.timer) {
								this.count = 60
								this.show = false
								this.timer = setInterval(() => {
									if (this.count > 0 && this.count <= 60) {
										this.count--
									} else {
										this.show = true
										clearInterval(this.timer)
										this.timer = null
									}
								}, 1000)
							}
						} else if (res.data.status === 500) {
							this.show = true
							this.$message({
								message: res.data.message,
								type: 'warning',
							})
							return
						}
					})
			} else {
				url = '/user/emailVerification'
				axiosRequest
					.post(url, {
						email: this.codeLoginForm.phone,
					})
					.then((res) => {
						if (res.data.status === 200) {
						} else if (res.data.status === 500) {
							this.$message.warning(res.data.message)
							return
						}
					})
			}
		},
		setInfo(res) {
			var userId = res.data.data.user.id
			var userPhone = res.data.data.user.phone
			var userEmail = res.data.data.user.email
			var company = res.data.data.user.company
			var position = res.data.data.user.position
			var roleId = res.data.data.user.role
			let username = res.data.data.user.username
			jsCookie.set('userId', userId)
			jsCookie.set('phone', userPhone)
			jsCookie.set('email', userEmail)
			jsCookie.set('wechat', res.data.data.user.wechat)
			jsCookie.set('company', company)
			jsCookie.set('position', position)
			jsCookie.set('role', roleId)
			jsCookie.set('username', username)
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					jsCookie.set('username', this.pwdLoginForm.username)
					if (this.showCodeLogin === false) {
						jsCookie.set('email', this.codeLoginForm.phone)
					} else {
						jsCookie.set('phone', this.codeLoginForm.phone)
					}
					axiosRequest
						.post('/user/login', {
							username: this.pwdLoginForm.username,
							password: this.pwdLoginForm.password,
						})
						.then((res) => {
							if (res.data.status === 200) {
								localStorage.setItem('selectTab', '操作日志')
								localStorage.setItem('selectTab1', '产品信息')
								this.setInfo(res)
								window.localStorage.setItem(
									res.data.data.tokenName,
									res.data.data.tokenValue
								)
                let salutation = this.constData.getSalutation();
                localStorage.setItem('salutation', salutation)
								this.$router.push('/home')
							} else if (res.data.status === 500) {
								this.$refs.VerificationCode.drawPic()
								this.$refs.VerificationCode.changeCode()
								this.$message({
									message: res.data.message,
									type: 'warning',
								})
							}
						})
				}
			})
		},
		submitFormLogin(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					jsCookie.set('username', this.pwdLoginForm.username)
					// jsCookie.set('password',this.pwdLoginForm.password)
					if (this.showCodeLogin === false) {
						jsCookie.set('email', this.codeLoginForm.phone)
						jsCookie.set('code', this.codeLoginForm.phoneCode)
						axiosRequest
							.post('/user/emailLogin', {
								email: this.codeLoginForm.phone,
								code: this.codeLoginForm.phoneCode,
							})
							.then((res) => {
								if (res.data.status === 200) {
									this.setInfo(res)
									if (this.showCodeLogin === false) {
										jsCookie.set('phone', userPhone)
									}
                  // else {
									// 	jsCookie.set('email', userEmail)
									// }
									window.localStorage.setItem(
										'access-verify',
										res.data.data.token
									)
									this.$router.push('/home')
								}
							})
					} else {
						jsCookie.set('phone', this.codeLoginForm.phone)
						jsCookie.set('code', this.codeLoginForm.phoneCode)
						axiosRequest
							.post('/user/phoneLogin', {
								phone: this.codeLoginForm.phone,
								code: this.codeLoginForm.phoneCode,
							})
							.then((res) => {
								if (res.data.status === 200) {
									this.setInfo(res)
									if (this.showCodeLogin === false) {
										jsCookie.set('phone', userPhone)
									}
                  // else {
									// 	jsCookie.set('email', userEmail)
									// }
									window.localStorage.setItem(
										res.data.data.tokenName,
										res.data.data.tokenValue
									)
                  let salutation = this.constData.getSalutation();
                  localStorage.setItem('salutation', salutation)
									this.$router.push('/home')
									this.$message({
										message: res.data.message,
										type: 'success',
									})
								} else {
									this.$message({
										message: res.data.message,
										type: 'warning',
									})
								}
							})
					}
				} else {
					return false
				}
			})
		},

		register() {
			//跳转到注册界面
			this.$router.push('/register')
		},
		confirmAdmin() {
			//跳转到重置管理员手机号页面
			this.$router.push('/confirmAdmin')
		},

		vxLogin() {
			this.$router.push('/wxLogin')
		},
		forget() {
			//跳转到忘记密码界面
			this.$router.push('/password')
		},
		submit(e) {
			if (e.keyCode == 13) {
				document.getElementById('login').click()
			}
		},
		submit1(e) {
			if (e.keyCode == 13) {
				document.getElementById('login1').click()
			}
		},
		handle() {
			// 此处进行表单重置
			this.$nextTick(() => {
				this.$refs['pwdLoginForm'].resetFields()
				this.$refs['codeLoginForm'].resetFields()
			})
		},

		// change() {
		//   this.showCodeLogin = !this.showCodeLogin
		//   if(this.showCodeLogin === false){
		//     this.ptext = "邮箱"
		//     this.pmessage= "请输入邮箱"
		//     this.showCodeLogin =false;
		//   }else{
		//     this.ptext = "手机号"
		//     this.pmessage= "请输入手机号"
		//     this.showCodeLogin =true;
		//   }
		//   this.codeLoginForm.phone = '';
		// },
	},
	mounted() {
		window.addEventListener('keydown', this.submit)
		window.addEventListener('keydown', this.submit1)
	},
	destroyed() {
		window.removeEventListener('keydown', this.submit, false)
		window.removeEventListener('keydown', this.submit1, false)
	},
}
</script>

<style scoped>
.login_container {
	overflow: hidden;
}
.el-form-item__error {
	color: #f56c6c;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 300px;
}
.login_logo {
	margin-bottom: 0;
	font-size: 10px;
	min-height: 31px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 31px;
	margin-top: 150px;
	color: #87878a;
	white-space: normal;
}
.fontText {
	margin-bottom: 0;
	font-size: 1rem;
	min-height: 31px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 31px;
	margin-top: 50px;
	color: #87878a;
	white-space: normal;
}

.login_box {
	width: 450px;
	margin: 45px auto;
}

.code {
	margin-top: 20px;
	margin-left: -30px;
}
.input {
	width: 260px;
	margin-right: -50px;
	margin-left: -131px;
	text-align: center;
}
.checkCode {
	margin-left: 50px;
}
.submit-btn {
	width: 450px;
	margin: 45px auto;
}
.forgetPw {
	margin-left: 90px;
}

.confirmAdmin {
	margin-left: 150px;
}
.forgetPw {
	margin-top: -1px;
}
</style>
