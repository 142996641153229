import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login'
import BindWechat from '../views/BindWechat'
import WeChatSuccess from '../views/WeChatSuccess'
import ScanQRSuccess from '../views/ScanQRSuccess'
import WxLogin from '../views/WxLogin'
import axiosRequest from '@/axios'
import jsCookie from 'js-cookie'

Vue.use(VueRouter)

const routes = [
	// {
	//   path: '/',
	//   name: 'backupFile',
	//   component: BackupFile
	// },

	{
		path: '/updateAdmin',
		name: 'updateAdmin',
		component: () => import('../views/UpdateAdmin'),
		//更改管理员信息页面只能从 信息完毕后跳转 不能从别的页面跳转
		beforeEnter: (to, from, next) => {
			//确认是不是从确认信息页面跳转
			if (from.name === 'confirmAdmin') {
				next()
			} else {
				//    跳回登录
				next('/login')
			}
		},
	},
	{
		path: '/confirmAdmin',
		name: 'confirmAdmin',
		component: () => import('../views/ConfirmAdmin'),
		props($route) {
			return {
				id: $route.query.id,
			}
		},
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/Register'),
	},
	{
		path: '/password',
		name: 'password',
		component: () => import('../views/Password'),
	},
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{
		path: '/bindWechat',
		name: 'bindWechat',
		component: BindWechat,
	},
	{
		path: '/weChatSuccess',
		name: 'weChatSuccess',
		component: WeChatSuccess,
	},
	{
		path: '/wxLogin',
		name: 'wxLogin',
		component: WxLogin,
	},
	{
		path: '/scanQRSuccess',
		name: 'ScanQRSuccess',
		component: ScanQRSuccess,
	},
	{
		path: '/admin',
		name: 'admin',
		component: () => import('@/views/Admin'),
	},
	{
		path: '/a',
		name: 'a',
		component: () => import('@/views/a'),
	},
	{
		path: '/admin1',
		name: 'admin1',
		component: () => import('@/views/admin1'),
	},
	{
		path: '/main',
		component: () => import('../components/Main'),

		children: [
			{
				path: '/home',
				name: 'home',
				meta: { requiresZoom: true },
				component: () => import('../views/Home'),
			},
			{
				path: '/backup',
				name: 'backup',
				meta: { requiresZoom: true },
				component: () => import('../views/Backup'),
			},
			{
				path: '/backupContent',
				name: 'backupContent',
				meta: { requiresZoom: true },
				component: () => import('../views/BackupContent'),
			},
			{
				path: '/dataRecovery',
				name: 'dataRecovery',
				meta: { requiresZoom: true },
				component: () => import('../views/DataRecovery'),
			},
			{
				path: '/encrypt',
				name: 'encrypt',
				meta: { requiresZoom: true },
				component: () => import('../views/Encrypt'),
			},
			{
				path: '/system',
				name: 'system',
				meta: { requiresZoom: true },
				component: () => import('../views/System'),
			},
			{
				path: '/user',
				name: 'user',
				meta: { requiresZoom: true },
				component: () => import('../views/User'),
			},
			{
				path: '/logAlarm',
				name: 'logAlarm',
				meta: { requiresZoom: true },
				component: () => import('../views/LogAlarm'),
			},
			{
				path: '/product',
				name: 'product',
				meta: { requiresZoom: true },
				component: () => import('../views/Product'),
			},
			{
				path: '/renew',
				name: 'renew',
				meta: { requiresZoom: true },
				component: () => import('../views/Renew'),
			},
			{
				path: '/contact',
				name: 'contact',
				meta: { requiresZoom: true },
				component: () => import('../views/Contact'),
			},
			{
				path: '/fileManage',
				name: 'fileList',
				meta: { requiresZoom: true },
				component: () => import('../views/fileManage'),
			},
			{
				path: '/preview',
				name: 'preview',
				meta: { requiresZoom: true },
				component: () => import('../views/Preview'),
			},
			{
				path: '/organize',
				name: 'organize',
				meta: { requiresZoom: true },
				component: () => import('../views/OrganizeMaintain'),
			},
			{
				path: '/dataPermission',
				name: 'dataPermission',
				meta: { requiresZoom: true },
				component: () => import('@/views/dataPermission'),
			},
			{
				path: '/test',
				name: 'test',
				component: () => import('@/views/test'),
			},
			{
				path: '/test1',
				name: 'test1',
				component: () => import('@/views/test1'),
			},
			{
				path: '/taskRollout',
				name: 'taskRollout',
				meta: { requiresZoom: true },
				component: () => import('@/views/taskRollout'),
			},
		],
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})
router.beforeEach((to, from, next) => {
	if (to.path.startsWith('/login')) {
		// window.localStorage.removeItem('access-verify')
		next()
	} else if (to.path === '/password') {
		next()
	} else if (to.path === '/register') {
		next()
	} else if (to.path === '/bindWechat') {
		next()
	} else if (to.path === '/weChatSuccess') {
		next()
	} else if (to.path === '/wxLogin') {
		next()
	} else if (to.path === '/scanQRSuccess') {
		next()
	} else if (to.path === '/confirmAdmin') {
		next()
	} else if (to.path === '/updateAdmin') {
		next()
	} else {
		let satoken = window.localStorage.getItem('satoken')
		let role = jsCookie.get('role')
		if (!satoken || role === undefined) {
		}
		// else {
		//     axiosRequest({
		//         url: 'https://yunbaobei.yeetrc.com:8081/user/checkToken',
		//         // url: 'http://39.97.164.42:8899/user/checkToken',
		//         method: 'get',
		//         headers: {
		//             'token': access
		//         }
		//     }).then((response) => {
		//         if (response.data.status != 200) {

		//             next({path: '/login'})
		//         }
		//     })
		// }
		next()
	}
})
export default router
