<template>
  <div class="register_container">
    <div class="register_logo">
      <img src="../assets/taiji.jpg" style="display: inline-block;width: 300px">
    </div>
    <h3 class="font1">云宝备——太极风控云备份数据管理平台</h3>

    <div class="register_box">
      <div style="margin-left: 150px;margin-top: 100px">
        <img :src="QRImgUrl"/>
      </div>
      <div style="margin-left: 160px;margin-top: 20px">
        <span>微信扫一扫登录</span>
      </div>
      <el-row>

        <el-col :span="19">
          <el-button type="text"  style="margin-top: 30px;margin-left: 85px;" @click="userLogin" >返回账号登录</el-button>
        </el-col>
        <el-col :span="5">
          <el-button type="text" @click="register" style="margin-top: 30px;margin-left: -40px;">注册账户</el-button>
        </el-col>
      </el-row>

      </div>
    </div>
</template>

<script>
import axiosRequest from "@/axios";
import QRCode from "qrcode";
import jsCookie from "js-cookie";

export default {
  name: "VXLogin",
  data(){
    return{
      QRImgUrl: '',
      uuid: '',
      timer:''
    }
  },
  mounted() {
    this.wxLogin()
  },
  methods:{
    register(){
      //跳转到注册界面
      this.$router.push('/register')
    },
    userLogin(){
      this.$router.push('/login')
    },
    wxLogin(){
      axiosRequest.get("/user/wxLogin").then(res => {
        QRCode.toDataURL(res.data.data.url, {errorCorrectionLevel: 'L', margin: 2, width: 128}, (err, url) => {
          if (err) throw err
          this.QRImgUrl = url
        })
        this.uuid = res.data.data.uuid
        this.timer = setInterval(async () => {
          axiosRequest.get("/user/uuid", {params: {uuid: this.uuid}}).then(res => {
            if (res.data.status === 200) {
              clearInterval(this.timer);
              var userId = res.data.data.user.id;
              var userPhone = res.data.data.user.phone;
              var userEmail = res.data.data.user.email;
              var company = res.data.data.user.company;
              var position = res.data.data.user.position;
              var roleId = res.data.data.user.role;
              var username = res.data.data.user.username;
              jsCookie.set('wechat',res.data.data.user.wechat)
              jsCookie.set('userId',userId)
              jsCookie.set('phone',userPhone)
              jsCookie.set('email',userEmail)
              jsCookie.set('wechat',res.data.data.user.wechat)
              jsCookie.set('company',company)
              jsCookie.set('position',position)
              jsCookie.set('role',roleId)
              jsCookie.set('username',username)
              window.localStorage.setItem(res.data.data.tokenName, res.data.data.tokenValue)
              this.$message({
                message: res.data.message,
                type: 'success'
              });
              let salutation = this.constData.getSalutation();
              localStorage.setItem('salutation', salutation)
              this.$router.push("/home")
            }else if (res.data.status === 201){
              clearInterval(this.timer);
              this.$message({
                message: res.data.message,
                type: 'warning'
              });
              this.wxLogin()
            }
          })
        }, 3000)
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }

}
</script>

<style scoped>
.register_logo{
  margin-bottom: 0;
  font-size: 10px;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  margin-top: 150px;
  color: #87878a;
  white-space: normal;
}
.font1{
  margin-bottom: 0;
  font-size: 1rem;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  margin-top:50px;
  color: #87878a;
  white-space: normal;
}
.font2{
  margin-bottom: -20px;
  font-size: 1rem;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -400px;
  height: 31px;
  margin-top:35px;
  white-space: normal;
  font-weight: normal;
}
.register_box{
  width: 450px;
  margin: 45px auto;
}
</style>
